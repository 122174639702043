<template>
  <div class="reports-container">
    <b-card class="mb-4">
      <b-row>
        <h2 class="section-label mx-1">Order Reports</h2>
      </b-row>
    </b-card>
    <b-card class="mb-4">
      <b-form @submit.prevent="fetchOrders" class="d-flex flex-column align-items-center">
        <b-row class="w-100 justify-content-center mb-1">
          <b-col cols="12" md="5" class="mb-2 mb-md-1">
            <b-form-group label="Start Date" label-for="start-date">
              <b-form-input id="start-date" v-model="startDate" type="datetime-local" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="End Date" label-for="end-date">
              <b-form-input id="end-date" v-model="endDate" type="datetime-local" required></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12" class=" ">
            <b-button type="submit" variant="primary" class="w-100">Fetch Orders</b-button>
          </b-col></b-row
        >
      </b-form>
    </b-card>
    <b-card>
      <b-tabs v-if="Object.keys(orders).length">
        <b-tab v-for="(orderList, origin) in orders" :key="origin" :title="origin">
          <div>
            <div class="statistics d-flex flex-wrap justify-content-between">
              <b-card class="stat-box" v-for="(label, key) in statisticsLabels" :key="key">
                <p class="stat-label">{{ label }}</p>
                <p class="stat-value">{{ calculateTotal(orderList, key) }}</p>
              </b-card>
              <b-card class="stat-box">
                <p class="stat-label">Total Orders Placed</p>
                <p class="stat-value">{{ orderList.length }}</p>
              </b-card>
            </div>
            <b-table
              :items="orderList"
              :fields="fields"
              responsive="sm"
              class="mb-4 cursor-pointer"
              @row-clicked="onRowClicked"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
            ></b-table>
          </div>
        </b-tab>
      </b-tabs>
      <div v-else class="text-center">
        <p>No orders found for the selected date range.</p>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BForm, BFormInput, BButton, BRow, BCard, BTable, BFormGroup, BTabs, BTab } from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import moment from "moment";

export default {
  components: {
    BForm,
    BFormInput,
    BButton,
    BTable,
    BFormGroup,
    BRow,
    BCard,
    BTabs,
    BTab,
  },
  data() {
    return {
      startDate: moment().startOf("day").format("YYYY-MM-DDTHH:mm"),
      endDate: moment().endOf("day").format("YYYY-MM-DDTHH:mm"),
      orders: {},
      statisticsLabels: {
        amount: "Amount Earned",
        shipping: "Total Cost of Shipping",
        coupon: "Total Coupons Used",
        service_charge: "Total Service Charge",
        sub_total: "Total Sub-total",
      },
      fields: [
        { key: "id", label: "Order ID", sortable: true },
        { key: "name", label: "Customer Name", sortable: true },
        { key: "order_time", label: "Order Date", sortable: true },
        { key: "origin", label: "Origin", sortable: true },
        { key: "address", label: "Address", sortable: true },
        { key: "token", label: "Payment", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "amount", label: "Amount", sortable: true },
      ],
      sortBy: "id",
      sortDesc: false,
    };
  },
  mounted() {
    this.fetchOrders();
  },
  methods: {
    onRowClicked(item) {
      this.$router.push(`/takeaway/shop/orders/${item.id}`);
    },
    fetchOrders() {
      useJwt
        .getReports({ startDate: moment(this.startDate).format("DD/MM/YYYY HH:mm"), endDate: moment(this.endDate).format("DD/MM/YYYY HH:mm") })
        .then((response) => {
          const categorizedByOrigin = response.data.data.categorizedByOrigin;

          // Transform orders
          const transformedOrders = {};
          const allOrders = [];
          for (const origin in categorizedByOrigin) {
            transformedOrders[origin] = categorizedByOrigin[origin].orders.map((order) => {
              console.log(order);
              const transformedOrder = {
                ...order,
                order_time: moment(order.order_time).format("DD/MM/YYYY HH:mm"),
                address: [order.address_line1, order.address_line2, order.city, order.count, order.zip].filter(Boolean).join(", "),
                status: this.getStatusText(order.status),
                amount: ((order.sub_total ?? 0) + (order.shipping ?? 0) + (order.service_charge ?? 0) - (order.coupon ?? 0)).toFixed(2),
              };
              allOrders.push(transformedOrder);
              return transformedOrder;
            });
          }

          // Create a new object with "all" as the first key
          const orderedTransformedOrders = {
            All: allOrders,
            ...transformedOrders,
          };

          this.orders = orderedTransformedOrders;
        });
    },
    calculateTotal(orderList, key) {
      return orderList.reduce((total, order) => total + (parseFloat(order[key]) || 0), 0).toFixed(2);
    },
    getStatusText(status) {
      switch (status) {
        case -1:
          return "Reject";
        case 0:
          return "New Order";
        case 1:
          return "Accept";
        case 2:
          return "Delivery";
        case 3:
          return "Completed";
        default:
          return "Unknown";
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";

.cursor-pointer tbody tr {
  cursor: pointer;
}

.statistics {
  text-align: left;
  margin-top: 20px;
}

.b-button {
  transition: background-color 0.3s ease;
}

.b-button:hover {
  background-color: #0056b3;
}

.statistics {
  text-align: left;
  margin-top: 20px;
}

.stat-box {
  flex: 1 1 200px;
  margin: 10px;
  padding: 10px;
  text-align: center;
}

.stat-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.stat-value {
  font-size: 1.2em;
  color: #007bff;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.b-card {
  width: 100%;
  max-width: 1200px;
}

.b-form {
  width: 100%;
}

.b-form-group {
  margin-bottom: 1.5rem;
}

.b-button {
  transition: background-color 0.3s ease;
}

.b-button:hover {
  background-color: #0056b3;
}
</style>
